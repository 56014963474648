// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
import "typeface-nunito"
import "typeface-alegreya"

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'


const addModelViewer = () => {
  const script = document.createElement("script")
  script.src = "https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
  script.async = true
  script.type = "module"
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    addModelViewer()
  }
}

export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LcRx4ciAAAAAKCR_yo5if85JwJC-0l7ZR6PPBjV">
            {element}
        </GoogleReCaptchaProvider>
    )
}